:root {
  --color-primary: #f5f200;
  --color-primary-shade: #c1b900;
  --color-secondary: #3e9741;
  --color-secondary-tint: #27ae60;
  --color-secondary-shade: #007000;
  --color-success: #54cc59;
  --color-tertiary: #0e5dac;
  --color-tertiary-tint: #87b9ec;
  --color-tertiary-light: #deecfa;
  --color-danger: #bf1521;
  --color-highlight: #deecfa;
  --color-highlight-tint: #f7fafe;
  --color-border-light: #eaeaea;
  --color-border: #939393;
  --color-light: #cfcfcf;
  --color-light-shade: #5d5d5d;
  --color-dark: 51 51 51;
  --color-background: #fff;
}
